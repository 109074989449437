import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ExportDialogComponent } from 'src/app/shared/component/export/export-dialog/export-dialog.component';
import { IVoucher } from 'src/app/shared/model/cityIdcore/voucher.model';
import { NeededPermission } from 'src/app/shared/service/permission.service';
import { VoucherService } from 'src/app/shared/service/voucher.service';
import { environment } from 'src/environments/environment';
// import { formatDate } from 'src/app/shared/util/util/formatting-utils';

@Component({
  selector: 'app-accesstoken-vouchers',
  templateUrl: './accesstoken-vouchers.component.html',
  styleUrls: ['./accesstoken-vouchers.component.scss']
})
export class AccesstokenVouchersComponent implements OnInit {
  @Input() accessTokenId;
  @Input() accessToken;
  columns = ['code', 'description', 'startDate', 'endDate','restrictedTo', 'maximum', 'remainingUsage'];
  dataSource: MatTableDataSource<IVoucher> = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private voucherService: VoucherService, private dialog: MatDialog,public datepipe: DatePipe) { }

  ngOnInit(): void {
    this.reload();
  }

  reload() {
    this.voucherService.findByToken(this.accessToken.id).subscribe(vs => {
      this.dataSource = new MatTableDataSource();
      this.dataSource.data = vs;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }


  permission() {
    return [NeededPermission.VoucherRead];
  }

  openExportDialog() {
    let prefix = environment.prefix;        
        let itemsFormatted = [];
        this.dataSource.filteredData.forEach((item) => {
                 itemsFormatted.push({
                                VoucherCode : item.id,
                                description: item.description,
                                ValidFrom: this.datepipe.transform(item.startDate, 'dd-MM-yyyy'),
                                ValidTo: this.datepipe.transform(item.endDate, 'dd-MM-yyyy'),
                                TargetAudience: item.restrictedTo,
                                maxTimes: item.maximum,                               
                                RemainingUse: item.remainingUse                                
                  });
               });

         this.dialog.open(ExportDialogComponent, {
            width: '80%', //sets width of dialog
            height:'5700px%', //sets width of dialog
            maxWidth: '100vw', //overrides default width of dialog
            maxHeight: '100vh', //overrides default height of dialog
            data: {data: itemsFormatted,
                  title: 'cityIdApp.cityIdcoreVouchers.title',
                  saveTag: prefix + "voucher_export"}
        });
 
  }

}
