export enum TransactionType {
  Saving = 'Saving',
  Payment = 'Payment',
  Cost ='Cost',
  PersonalSaving = 'PersonalSaving',
  Recheargeable = 'Rechargeable',
  SavingsGoal="SavingsGoal",
  Product="Product",
  BudgetChange="BudgetChange",
  RefundToBudget="RefundToBudget",
  Provisioning="Provisioning",
  ProgramCost = "ProgramCost",
  Commission = "Commission",
  ExternalPayment = "ExternalPayment",
  MollieCost = "MollieCost",
  PersonalSavingCost = "PersonalSavingCost",
  Redeem = "Redeem",
  MonetaryChange = "MonetaryChange"
}
