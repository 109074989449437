import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IWallet } from 'src/app/shared/model/cityIdcore/wallet.model';
import { WalletService } from 'src/app/shared/service/wallet.service';
// import { formatDate } from 'src/app/shared/util/util/formatting-utils';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExtendWalletComponent } from '../extend-wallet/extend-wallet.component';
import { NeededPermission } from 'src/app/shared/service/permission.service';


@Component({
  selector: 'app-accesstoken-wallets',
  templateUrl: './accesstoken-wallets.component.html',
  styleUrls: ['./accesstoken-wallets.component.scss']
})
export class AccesstokenWalletsComponent implements OnInit {

  @Input() accessToken;
  columns = ['id', 'name', 'amount', 'unit', 'type', 'validityStart', 'validityEnd', 'add'];
  dataSource: MatTableDataSource<IWallet> = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  constructor(private walletService: WalletService,
    private dialog: MatDialog,
    protected router: Router) { }

  ngOnInit(): void {
    this.reload();
  }

  permission() {
    return [ NeededPermission.WalletRead ];
  }

  
  hasExtendPermission() {
    return [ NeededPermission.WalletUpdate ];
  }


  reload() {
    this.walletService.findbyaccessToken(this.accessToken.id).subscribe(res => {
      this.dataSource = new MatTableDataSource(res.body);
      this.dataSource.sort = this.sort;
    });
  }

  // format(s: string): string {
  //   return formatDate(s);
  // }

  add(element: any) {
    console.log(element);
    this.openDialog(element);
  }

  openDialog(element: any) {
    const dialogRef = this.dialog.open(ExtendWalletComponent, {
      width: '1000px',
      data: {
        wallet: element
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      const currentUrl = this.router.url;
      this.reload();
    });
  }

}
