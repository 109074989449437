import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IVoucher, IVoucherTransaction } from '../model/cityIdcore/voucher.model';
import { IBaseVoucher } from '../model/cityIdcore/voucher.model';
import { IVoucherRedeem } from 'src/app/component/vouchers/bulk-redeem/bulk-redeem.component';

@Injectable({ providedIn: 'root' })
export class VoucherService {
  public prefix = environment.SERVER_API_URL + 'api/admin';

  constructor(protected http: HttpClient) {}

  getPartnerPrefix(partnerId: number) {
    return this.prefix + "/partners/" + partnerId + "/vouchers";
  }

  all() : Observable<IVoucher[]> {
    return this.http.get<IVoucher[]>(this.prefix + "/vouchers");
  }

  get(id : number) : Observable<IVoucher> {
    return this.http.get<IVoucher>(this.prefix + "/vouchers/" + id);
  }

  findByPartnerId(partnerId: number) : Observable<IVoucher[]> {
    return this.http.get<IVoucher[]>(this.getPartnerPrefix(partnerId));
  }

  findByToken(accessTokenId: number) : Observable<IVoucher[]> {
    return this.http.get<IVoucher[]>(this.prefix + "/access-tokens/" + accessTokenId + "/vouchers");
  }

  delete(id : number) : Observable<any> {
    return this.http.delete(this.prefix + "/vouchers/" + id);
  }

  insert(v : IBaseVoucher) : Observable<any> {
    return this.http.post(this.prefix + "/vouchers", v);
  }

  uploadAll(newVouchers: IVoucher[]): Observable<HttpResponse<any[]>>  {
    return this.http.post<any[]>(this.prefix + "/vouchers-upload", newVouchers, {observe : 'response'});
  }

  bulkRedeem(newVouchers: IVoucherRedeem[]): Observable<HttpResponse<any[]>>  {
    return this.http.post<any[]>(this.prefix + "/vouchers/bulk-redeem", newVouchers, {observe : 'response'});
  }


  update(id: number, v: IBaseVoucher) : Observable<any> {
    return this.http.put(this.prefix + "/vouchers/" + id, v);
  }

  deletePartner(id: number, partnerId: number) : Observable<any> {
    return this.http.delete(this.prefix + "/vouchers/" + id + "/partners/" + partnerId);
  }

  addPartner(id: number, partnerId : number) : Observable<any> {
    return this.http.put(this.prefix + "/vouchers/" + id + "/partners/" + partnerId, {});
  }

  findVoucherTransaction(id: number) : Observable<IVoucherTransaction> {
    return this.http.get<IVoucherTransaction>(this.prefix + "/transactions/" + id + "/voucher");
  }
}
